$(function() {
  function scrollToLastMessage() {
    const lastMessage = document.querySelector("#currentConversation .message:last-child");

    const alignToTop = true;
    if (lastMessage) lastMessage.scrollIntoView(alignToTop);
  }

  document.addEventListener('scroll_to_last_message', scrollToLastMessage);
})
