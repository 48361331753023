$(function() {
  $(document).on('change', 'input[data-toggle="radiobutton-collapse"]', function(e) {
    var radio = $(this);
    var target = $(radio.data('href'));

    var checked = radio.is(':checked');

    if (checked) {
      target.addClass('show');

      // hides all other radios
      $radios = $('input[name="' + radio.attr('name') + '"]').not(radio);

      $radios.each(function() {
        var target = $(this).data('href');

        if(!target) {
          return;
        }

        $(target).removeClass('show');
      });
    }
  });
});
