import React, { Component } from 'react';
import styled from 'styled-components';
import Annotations from './annotations';
import Bubble from './bubble';
import Portal from './portal';

const Container = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  right: -${(props) => (props.bubbleSize ? props.bubbleSize + 15 : 50)}px;

  display: flex;

  z-index: ${(props) => (props.annotationsVisible ? 2 : 1)};
`;

const Wrapper = styled.div`
  position: relative;
`;

export default class Annotator extends Component {
  constructor(props, S = {}, inherit = false) {
    super(props);

    if (inherit) {
      return;
    }

    let annotationsLength = this.props.annotations ? this.props.annotations.length : 0;

    this.targetElement = this.props.targetElement;
    this.parentElement = this.props.parentElement;

    this.annotationService = this.props.annotationService;

    this.state = {
      bubbleVisible: this.props.bubbleVisible || annotationsLength > 0,
      annotationsVisible: this.props.annotationsVisible || false,

      bubbleColor: this.props.bubbleColor || '#09203c',
      bubbleSize: this.props.bubbleSize || 30,

      targetInput: this.props.targetInput,

      annotations: this.props.annotations || [],
      newAnnotationText: '',

      errorMessage: '',

      title: this.props.title,

      top: null,
      left: null,

      defaultAvatarUrl: this.props.defaultAvatarUrl,
    };
  }

  componentDidMount() {
    this.props.onRef(this);

    this.targetElement.on('focus', this.onTargetFocus);
    this.targetElement.on('focusout', this.onTargetFocusOut);

    this.hookOnInstance();
  }

  componentWillUnmount() {
    this.props.onRef(undefined);

    this.unhookOnInstance();
  }

  hookOnInstance = () => {
    if (this.props.collapseParentElement && this.props.collapseParentElement.length > 0) {
      this.hookOnCollapseParents();
    }

    if (this.props.cocoonParentElement && this.props.cocoonParentElement.length > 0) {
      this.hookOnCocoonParents();
    }
  };

  hookOnCollapseParents = () => {
    let self = this;

    this.props.collapseParentElement.on('hidden.bs.collapse', function() {
      self.resolveAnnotations(false, true);
    });
  };

  hookOnCocoonParents = () => {
    let self = this;

    this.props.cocoonParentElement.on('cocoon:after-remove-element', function(e) {
      self.resolveAnnotations(false, false);

      self.unhookOnInstance();

      e.stopPropagation();
    });
  };

  unhookOnInstance = () => {
    if (this.props.collapseParentElement && this.props.collapseParentElement.length > 0) {
      this.props.collapseParentElement.off('hidden.bs.collapse');
    }

    if (this.props.cocoonParentElement && this.props.cocoonParentElement.length > 0) {
      this.props.cocoonParentElement.off('cocoon:after-remove-element');
    }
  };

  onTargetFocus = () => {
    if (this.props.onTargetFocusFn !== undefined) {
      this.props.onTargetFocusFn();
    }

    this.showBubble();
  };

  showBubble = () => {
    if (!this.state.annotationsVisible) {
      this.setState({ bubbleVisible: true });
    }
  };

  onTargetFocusOut = () => {
    this.hideBubble();
  };

  hideBubble = () => {
    if (!this.state.bubbleVisible || this.annotationsCount() > 0) {
      return;
    }

    setTimeout(() => {
      this.setState({ bubbleVisible: false });
    }, 150);
  };

  newAnnotationTextFn = (text) => {
    this.setState({ newAnnotationText: text });
  };

  targetElementName = () => {
    return this.state.targetInput;
  };

  submitAnnotation = () => {
    let annotation = {
      annotation: {
        field_name: this.targetElementName(),
        content: this.state.newAnnotationText,
        internal: this.state.noteMode,
      },
    };

    this.annotationService.createAnnotation(
      annotation,
      (newAnnotation) => {
        const newAnnotations = this.state.annotations.concat(newAnnotation);
        this.setState({ annotations: newAnnotations, newAnnotationText: '', noteMode: false, errorMessage: null });
      },
      () => {
        this.setState({
          errorMessage: 'Erreur lors de la création du commentaire, veuillez réessayer ultérieurement.',
        });
      }
    );
  };

  openAnnotations = () => {
    if (this.props.onAnnotationsVisibleFn) {
      this.props.onAnnotationsVisibleFn(this.targetElementName());
    }

    this.setState({ annotationsVisible: true, bubbleVisible: false });
  };

  annotationsVisible = () => {
    return this.state.annotationsVisible;
  };

  closeAnnotations = () => {
    this.setState({
      annotationsVisible: false,
      bubbleVisible: this.props.bubbleVisible || this.annotationsCount() > 0,
    });
  };

  resolveAnnotations = (showConfirm = true, canChangeState = true) => {
    const confirmResolveText = 'Une fois fermée, vous ne pourrez plus accéder à cette conversation.';

    if (this.state.annotations.length === 0) {
      return;
    }

    if (showConfirm && !window.confirm(confirmResolveText)) {
      return;
    }

    this.annotationService.resolveAnnotations(
      this.targetElementName(),
      () => {
        if (canChangeState) {
          this.setState({ annotations: [], annotationsVisible: false, bubbleVisible: false, errorMessage: null });
        }
      },
      () => {
        if (canChangeState) {
          this.setState({
            errorMessage: 'Erreur lors de la résolution des commentaires, veuillez réessayer ultérieurement.',
          });
        }
      }
    );
  };

  deleteAnnotation = (annotationID) => {
    this.annotationService.deleteAnnotation(
      annotationID,
      () => {
        this.setState({
          annotations: this.state.annotations.filter((annotation) => annotation.id !== annotationID),
        });
      },
      () => {
        this.setState({
          errorMessage: 'Impossible de supprimer le commentaire, veuillez réessayer ultérieurement.',
        });
      }
    );
  };

  canDeleteAnnotation = (annotation) => {
    return this.annotationService.canDeleteAnnotation(annotation);
  };

  canCreateNote = () => {
    return this.annotationService.canCreateNote();
  };

  setNoteMode = (noteMode) => {
    this.setState({ noteMode: noteMode });
  };

  annotationsCount = () => {
    return this.state.annotations.length;
  };

  render() {
    return (
      <Portal targetElement={this.targetElement} parentElement={this.parentElement}>
        <Container annotationsVisible={this.state.annotationsVisible}>
          <Wrapper>
            <Bubble
              visible={this.state.bubbleVisible}
              counter={this.annotationsCount()}
              color={this.state.bubbleColor}
              size={this.state.bubbleSize}
              clickFn={this.openAnnotations}
            />
            <Annotations
              title={this.state.title}
              visible={this.state.annotationsVisible}
              noteMode={this.state.noteMode}
              annotations={this.state.annotations}
              newAnnotationText={this.state.newAnnotationText}
              defaultAvatarUrl={this.state.defaultAvatarUrl}
              errorMessage={this.state.errorMessage}
              formSubmitFn={this.submitAnnotation}
              newAnnotationTextFn={this.newAnnotationTextFn}
              closeFn={this.closeAnnotations}
              resolveFn={this.resolveAnnotations}
              deleteFn={this.deleteAnnotation}
              canDeleteFn={this.canDeleteAnnotation}
              canCreateNote={this.canCreateNote()}
              setNoteModeFn={this.setNoteMode}
            />
          </Wrapper>
        </Container>
      </Portal>
    );
  }
}
