$(function() {
  $(document).on('click', '.js-accordion-show-next', function() {
    var currentItem = $(this).closest('.accordion-item');
    var currentSection = currentItem.find('.accordion-collapse');
    var currentBtn = currentItem.find('.accordion-button[href="#' + currentSection.attr('id') + '"]');

    if (currentItem.length) {
      currentBtn.addClass('collapsed');
      currentSection.removeClass('show');
    }

    var nextItem = currentItem.next('.accordion-item');
    var nextSection = nextItem.find('.accordion-collapse');
    var nextId = nextSection.attr('id');
    var nextBtn = nextItem.find('.accordion-button[href="#' + nextId + '"]');

    if (nextSection.length) {
      nextBtn.removeClass('collapsed');
      nextSection.addClass('show');

      document.getElementById(nextId).scrollIntoView({ behavior: 'smooth' });
    }
  });
});
