var loadUITabs = function() {
  if ($('.ui-tabs').length > 0) {
    $(document).on('click', '.ui-tabs .nav-link', function(e) {
      var tabLink = $(e.target).closest('.nav-link');
      showingTab(tabLink);
    });

    setActiveTab();
  }
};

var showingTab = function(tabLink) {
  persistAnchorInCurrentUrl(tabLink);

  var tabId = tabLink.attr('href');
  var tab = $(tabId);
  loadDynamicContentInTab(tab);
};

var setActiveTab = function() {
  var tabId = window.location.hash;
  var tabLink = $('.nav-link[href="' + tabId + '"]');
  if (tabLink.length == 0) {
    showFirstTab();
  } else {
    showTab(tabLink);
  }
};

var showFirstTab = function() {
  var tabLink = $('.ui-tabs .nav-link:first');
  showTab(tabLink);
};

var showTab = function(tabLink) {
  var tab = new bootstrap.Tab(tabLink);
  if (tab.length == 0) return;

  tab.show();
  $(tabLink).trigger('click'); // Force tab show logic
  window.scrollTo(0, 0); // Avoid scrolling to tab anchor
};

var loadDynamicContentInTab = function(tab) {
  tab.find('.js-loading-trigger').each(function() {
    var trigger = $(this);
    var src = trigger.data('src');

    $.ajax({
      url: src,
      dataType: 'script',
    }).done(function() {
      trigger.remove();
    });
  });
};

var persistAnchorInCurrentUrl = function(tabLink) {
  var url = window.location.href;
  var tabId = tabLink.attr('href');
  if (tabId === undefined) return;

  if (url.indexOf('#') === -1) {
    url += tabId;
  } else {
    url = url.replace(/#(.*)$/, tabId);
  }

  history.replaceState(null, null, url);
};

document.addEventListener('DOMContentLoaded', loadUITabs);
