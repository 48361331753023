$(function() {
  var stopPolling = function(container) {
    container.trigger('async-stop');
  }

  var isProgressAsyncPolling = function(container) {
    return container.hasClass('progress-async-polling');
  }

  var isProgressCompleted = function(progress) {
    if(!progress) return;

    return ('computationProgressCompleted' in progress.dataset);
  }

  var handleCompleted = function(container) {
    var root = container.closest(".nc-progress-async-component");

    if(root) {
      var redirectUrl = root.data('redirect');

      if(redirectUrl) {
        window.location.href = redirectUrl;
      }
    }
  }

  document.addEventListener('render_async_load', function(event) {
    const container = event.container;

    if(!isProgressAsyncPolling(container)) return;
    
    const renderingStatus = container[0].querySelector('[data-computation-progress-completed]');

    if (isProgressCompleted(renderingStatus)) {
      stopPolling(container);
      handleCompleted(container);
    }
  });

  document.addEventListener('render_async_error', function(event) {
    var container = event.container;

    if(isProgressAsyncPolling(container)) {
      stopPolling(container);
    }
  });
});
